<template>
  <div>
    <load-spinner v-if="isLoadingNotifikasi"></load-spinner>
    <base-header base-title="Manajemen Notifikasi"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="titleName"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <button
                :disabled="isBtnCanEdit"
                class="btn btn-outline-primary btn-sm"
                @click="BeforeSave"
                id="simpan"
              >
                <i :class="btn.icon"></i> {{ btn.text }}
              </button>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
             <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Template Id</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="detailTemplate.templateId"
                  :disabled="isDisabled"
                  id="id-template"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Nama Template</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="detailTemplate.templateName"
                  id="nama-template"
                />
              </div>
            </div>
          </div>
          <v-client-table
            :data="channelTemplate"
            :columns="columns"
            :options="options"
            ref="table"
          >
            <div slot="child_row" slot-scope="props">
              <template-channel :data-props="props.row" />
              <!-- <div><b>Text:</b> {{props.row}}</div> -->
            </div>
            <div slot="message" slot-scope="{ row }">
              <span>{{ row | showMessage }}</span>
            </div>
            <div slot="show" slot-scope="{ row }">
              <input :id="`select-${row.templateName}`" type="checkbox" v-model="checkBox[row.templateName]" />
            </div>
          </v-client-table>
        <div class="card">
            <div class="card-body">
                <backup-hierarki-notification></backup-hierarki-notification>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import TemplateChannel from "./TemplateChannel.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import LoadSpinner from "./../../../components/_general/LoadSpinner";
import TemplateNotification from "../../model/template-notification";
import BackupHierarkiNotification from "../../components/BackupHierarkiNotification.vue"
export default {
  components: {
    BaseTitle,
    BaseHeader,
    TemplateChannel,
    LoadSpinner,
    BackupHierarkiNotification
  },
  filters: {
    showMessage(item) {
      if (item.templateName == "smsTemplate") {
        return item.content;
      } else if (item.templateName == "emailTemplate") {
        return item.subject;
      } else if (item.templateName == "fcmTemplate") {
        return item.content;
      } else if (item.templateName == "whatsappTemplate") {
        return item.whatsappTemplateId;
      }
      return " - ";
    },
  },
  data() {
    return {
      templateNotif: new TemplateNotification(),
      isDisabled: false,
      titleName: "",
      btn: {
        text: "Simpan",
        icon: "fa fa-save", // flaticon2-edit flaticon2-add-1
      },
      channelTemplate: [],
      columns: ["show", "templateName", "message"],
      options: {
        uniqueKey: "templateName",
        sortable: [],
        headings: {
          templateName: "Nama Channel",
          message: "Pesan",
        },
        columnsClasses: {
          templateName: "w-25",
          message: "w-100",
        },
        filterByColumn: false,
        stickyHeader: true,
        filterable: false,
        childRowTogglerFirst: false,
        pagination: {
          show: false,
        },
      },
      checkBox: {
        smsTemplate: false,
        emailTemplate: false,
        fcmTemplate: false,
        whatsappTemplate: false,
      },
    };
  },
  computed: {
    ...mapState("NotifikasiStore", {
      detailTemplate: (state) => state.listDetailTemplate,
      smsTemplate: (state) => state.smsTemplate,
      fcmTemplate: (state) => state.fcmTemplate,
      whatsappTemplate: (state) => state.whatsappTemplate,
      emailTemplate: (state) => state.emailTemplate,
      isLoadingNotifikasi: (state) => state.isLoadingNotifikasi,
      isBtnCanEdit: (state) => state.isBtnCanEdit,
      isHaveTemplate: state => state.isHaveTemplate,
      listChannelUsed: state => state.listChannelUsed,
      listHierarki: state => state.hierarkiParent
    }),
  },
  async mounted() {
    this.setNullHierarki()
    this.setListChannelNull([])
    const params = this.$route.params.id;
    if (params) {
      if (params == "add") {
        // nambah
        this.isDisabled = false
        this.titleName = "Tambah Template";
        this.setBtnEdit(false);
        this.setDefaultTemplate();
      } else {
          this.isDisabled = true
        this.setLoading(true);
        await this.getDetailbyId(this.$route.params.id);
        this.titleName = this.detailTemplate.templateName
          ? this.detailTemplate.templateName
          : "Edit Notifikasi";
      }
    }
    const dataString = JSON.stringify(this.detailTemplate);
    const dataParse = JSON.parse(dataString);
    if (dataParse.smsTemplate) {
      this.checkBox.smsTemplate = dataParse.smsTemplate.content ? true : false;
      let data = this.setDataTemplate("smsTemplate", dataParse.smsTemplate);
      if (!data.content) {
        data = { ...data, ...this.templateNotif.smsTemplate };
      }
      this.channelTemplate.push(data);
      this.setSmsTemplate(data);
    } else {
        let data = this.setDataTemplate("smsTemplate", {})
        data = {...data, ...this.templateNotif.smsTemplate}
        this.channelTemplate.push(data)
        this.setSmsTemplate(data)
    }
    if (dataParse.emailTemplate) {
      this.checkBox.emailTemplate = dataParse.emailTemplate.subject
        ? true
        : false;
      let data = this.setDataTemplate("emailTemplate", dataParse.emailTemplate);
      if(!data.bcc) data.bcc = []
      if(!data.cc) data.cc = []
      if (!data.subject) {
        data = { ...data, ...this.templateNotif.emailTemplate };
      }
      this.channelTemplate.push(data);
      this.setEmailTemplate(data);
    } else {
        let data = this.setDataTemplate("emailTemplate", {})
        data = {...data, ...this.templateNotif.emailTemplate}
        this.channelTemplate.push(data)
        this.setEmailTemplate(data)
    }
    if (dataParse.fcmTemplate) {
      this.checkBox.fcmTemplate = dataParse.fcmTemplate.content ? true : false;
      let data = this.setDataTemplate("fcmTemplate", dataParse.fcmTemplate);
      if (!data.content) {
        data = { ...data, ...this.templateNotif.fcmTemplate };
      }
      this.channelTemplate.push(data);
      this.setFcmTemplate(data);
    }else {
        let data = this.setDataTemplate("fcmTemplate", {})
        data = {...data, ...this.templateNotif.fcmTemplate}
        this.channelTemplate.push(data)
        this.setFcmTemplate(data)
    }
    if (dataParse.whatsappTemplate) {
      this.checkBox.whatsappTemplate = dataParse.whatsappTemplate
        .whatsappTemplateId
        ? true
        : false;
      let data = this.setDataTemplate(
        "whatsappTemplate",
        dataParse.whatsappTemplate
      );
      if (!data.whatsappTemplateId) {
        data = { ...data, ...this.templateNotif.whatsappTemplate };
      }
      this.channelTemplate.push(data);
      this.setWhatsappTemplate(data);
    } else {
        let data = this.setDataTemplate("whatsappTemplate", {})
        data = {...data, ...this.templateNotif.whatsappTemplate}
        this.channelTemplate.push(data)
        this.setWhatsappTemplate(data)
    }
  },
  watch: {
      "detailTemplate.templateId": function(val) {
          this.detailTemplate.templateId =  val ? val.trimLeft().replaceAll(' ', '_') : val
      },
       "checkBox.smsTemplate"(val) {
         this.setHierarkiParent({active: val, name: 'SMS'})
      },
      "checkBox.emailTemplate"(val) {
          this.setHierarkiParent({active: val, name: 'EMAIL'})
      },
      "checkBox.fcmTemplate"(val) {
          this.setHierarkiParent({active: val, name: 'FCM'})
      },
      "checkBox.whatsappTemplate"(val) {
          this.setHierarkiParent({active: val, name: 'WA'})
      }
  },
  methods: {
    replaceTemplate(name) {
      if (name.length > 0) {
        return name.toString().trim().replaceAll(" ", "_");
      }
    },
    setDataTemplate(name, data) {
      return {
        templateName: name,
        ...data,
      };
    },
    ...mapActions({
      getDetailbyId: "NotifikasiStore/GET_TEMPLATE_CHANNEL_BY_ID",
      postNotifTemplate: "NotifikasiStore/POST_TEMPLATE_CHANNEL",
      checkDetailId: 'NotifikasiStore/CHECK_TEMPLATE_CHANNEL_BY_ID'
    }),
    ...mapMutations({
      setNullHierarki: 'NotifikasiStore/SET_HIERARKI_NULL',
      setSmsTemplate: "NotifikasiStore/SET_SMS_TEMPLATE",
      setFcmTemplate: "NotifikasiStore/SET_FCM_TEMPLATE",
      setWhatsappTemplate: "NotifikasiStore/SET_WHATSAPP_TEMPLATE",
      setEmailTemplate: "NotifikasiStore/SET_EMAIL_TEMPLATE",
      setBtnEdit: "NotifikasiStore/SET_IS_BUTTON_CAN_EDIT",
      setLoading: "NotifikasiStore/SET_LOADING_NOTIFIKASI",
      setDefaultTemplate: "NotifikasiStore/SET_TO_DEFAULT_NOTIF_TEMPLATE",
      setHierarkiParent: 'NotifikasiStore/SET_HIERARKI_PARENT',
      setListChannelNull: 'NotifikasiStore/SET_LIST_CHANNEL_USED'
    }),
    save(deleteTemplate = false) {
      let params = this.$route.params.id;
      let templateId;
      if (params == "add") {
        templateId = this.replaceTemplate(this.detailTemplate.templateId);
      } else {
        templateId = this.detailTemplate.templateId;
      }

      const dataTmp = {
        templateName: this.detailTemplate.templateName,
        templateId,
      };
      dataTmp.whatsappTemplate = this.whatsappTemplate;
      dataTmp.smsTemplate = this.smsTemplate;
      dataTmp.emailTemplate = this.emailTemplate;
      dataTmp.fcmTemplate = this.fcmTemplate;
      const data = JSON.parse(JSON.stringify(dataTmp));
      delete data.smsTemplate.templateName;
      delete data.emailTemplate.templateName;
      delete data.fcmTemplate.templateName;
      delete data.whatsappTemplate.templateName;
      if (this.checkBox.smsTemplate) {
        data.smsTemplate.requestMethod = "CREATE_OR_UPDATE";
      } else {
        data.smsTemplate.requestMethod = "DELETE";
      }
      if (this.checkBox.emailTemplate) {
        data.emailTemplate.requestMethod = "CREATE_OR_UPDATE";
      } else {
        data.emailTemplate.requestMethod = "DELETE";
      }
      if (this.checkBox.fcmTemplate) {
        data.fcmTemplate.requestMethod = "CREATE_OR_UPDATE";
      } else {
        data.fcmTemplate.requestMethod = "DELETE";
      }
      if (this.checkBox.whatsappTemplate) {
        data.whatsappTemplate.requestMethod = "CREATE_OR_UPDATE";
      } else {
        data.whatsappTemplate.requestMethod = "DELETE";
      }
      if (deleteTemplate) {
        params = "delete";
      }      
      data.channelUsed = this.listChannelUsed
      this.postNotifTemplate({ data, params });
    },
    checkHierarki(){
        let isCheck = true
        for(let i =0; i < this.listHierarki.length; i++) {
            let check = this.isChannelHierarkiSet(this.listChannelUsed, this.listHierarki[i].name)
            if(!check) {
                isCheck = false
                break;
            }
        }
        return isCheck
    },
    isChannelHierarkiSet(list, findCheck) {
        let isCheck = false
        for(let i = 0; i < list.length; i++) {
            if(findCheck == list[i].channel){
                isCheck = true
                break;
            }
            if(list[i].backupChannel) {
                isCheck = this.isChannelHierarkiSet(list[i].backupChannel, findCheck)
                if(isCheck) break
            }
        }
        return isCheck
    },
    async BeforeSave() {
     if (!this.detailTemplate.templateId) {
        toastr.error("Silahkan isi Id Template");
        document.getElementById('id-template').focus()
        return;
      }
      if (!this.detailTemplate.templateName) {
        toastr.error("Silahkan isi nama Template");
        document.getElementById('nama-template').focus()
        return;
      }
      const params = this.$route.params.id;
      let showData = this.checkValidation(params);
      if (!showData) {
        return;
      }
      let deleteTemplate = false;
      if (
        !this.checkBox.smsTemplate &&
        !this.checkBox.emailTemplate &&
        !this.checkBox.fcmTemplate &&
        !this.checkBox.whatsappTemplate
      ) {
        deleteTemplate = true;
      }
      if(!this.isDisabled) {
          const templateId = this.replaceTemplate(this.detailTemplate.templateId);
        //   await this.checkTemplateId(templateId)
        if(this.isHaveTemplate) {
            toastr.error('ID ini sudah digunakan')
            return
        }
      }
      if(this.listChannelUsed.length == 0) {
          const element = document.getElementsByClassName("card-body");
          element[0].scrollIntoView();
          toastr.error('Lengkapi Untuk Channel Hierarki nya')
          return
      }
       let isCheck = this.checkHierarki()
        if(!isCheck){
            const element = document.getElementsByClassName("card-body");
            element[0].scrollIntoView();
            toastr.error('Silahkan periksa apakah channel yang tersedia sudah berada di dalam Hierarki')
            return
        } 
      swal({
        title: deleteTemplate
          ? "Apakah Anda yakin menghapus ?"
          : "Apakah data sudah benar?",
        text: deleteTemplate
          ? "Template ini akan terhapus, ingin dilanjutkan"
          : "",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Lanjutkan",
      }).then((result) => {
        if (result.value) {
          this.save(deleteTemplate);
        }
      });
    },
    async checkTemplateId(id){
        await this.checkDetailId(id)
    },
    checkValidation(route) {
      let checkVerif = true;
      let checkDetail = true;
      if (route == "add") {
        checkVerif = false;
      }
      if (this.checkBox.smsTemplate) {
        checkVerif = true;
        if (!this.smsTemplate.content) {
          checkDetail = false;
          toastr.error("Silahkan isi dahulu Content pada Channel Sms");
        }
      }
      if (this.checkBox.emailTemplate) {
        checkVerif = true;
        if (!this.emailTemplate.subject) {
          checkDetail = false;
          toastr.error("Silahkan isi dahulu Subject pada Channel Email");
        } 
            // console.log('ini terjadi', this.checkEmail(this.emailTemplate.sender))
        if(!this.emailTemplate.sender || !this.checkEmail(this.emailTemplate.sender)) {
            checkDetail = false
            toastr.error("Silahkan sesuaikan format email pada Channel Email")
        }
      }
      if (this.checkBox.fcmTemplate) {
        checkVerif = true;
        if (!this.fcmTemplate.content) {
          checkDetail = false;
          toastr.error("Silahkan isi dahulu Content pada Channel FCM");
        }
      }
      if (this.checkBox.whatsappTemplate) {
        checkVerif = true;
          let checkParams = false
          for(let i = 0; i <  this.whatsappTemplate.paramMappings.length; i++) {
              if(!this.whatsappTemplate.paramMappings[i].whatsappParam || !this.whatsappTemplate.paramMappings[i].templateParam){
                  checkParams = true
              }
          }
          if (
          !this.whatsappTemplate.whatsappTemplateId || checkParams
        ) {
          checkDetail = false;
          toastr.error(
            "Silahkan isi dahulu detail Whatsapp pada Channel Whatsapp"
          );
        }
      }
      if (!checkVerif) {
        toastr.error("Silahkan Pilih dahulu Channel yang ingin digunakan");
      }
      return checkVerif && checkDetail;
    },
    checkEmail(item) {
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (filter.test(item)) {
            return true;
        } else {
            return false;
        }
    }
  },
};
</script>
