<template>
  <div class="container">
    <template v-if="dataProps.templateName == 'smsTemplate'">
      <div>
        <div class="row mb-4">
          <div class="col-md-2 text-right pt-2">Content</div>
          <div class="col-md-8">
            <textarea rows="5" type="text" class="form-control" id="sms-content" v-model="smsTemplate.content"/>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 text-right">Status</div>
          <div class="col-md-8">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="submitted"
                v-model="smsTemplate.status"
                value="SUBMITTED"
              />
              <label class="form-check-label" for="submitted">Submitted</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="approved"
                v-model="smsTemplate.status"
                value="APPROVED"
              />
              <label class="form-check-label" for="approved">Approved</label>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 text-right">Type</div>
          <div class="col-md-8">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="premium"
                 v-model="smsTemplate.type"
                value="PREMIUM"
              />
              <label class="form-check-label" for="premium">Premium</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="reguler"
                v-model="smsTemplate.type"
                value="REGULER"
              />
              <label class="form-check-label" for="reguler">Reguler</label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="dataProps.templateName == 'emailTemplate'">
      <div>
        <div class="row mb-4">
          <div class="col-md-2 text-right pt-2">Subject</div>
          <div class="col-md-8">
            <input type="text" id="email-subject" class="form-control" v-model="emailTemplate.subject"/>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 text-right pt-2">Sender</div>
          <div class="col-md-8">
            <input type="text" id="email-sender" class="form-control" v-model="emailTemplate.sender"/>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 text-right pt-2">CC</div>
          <div class="col-md-8">
            <template v-if="emailTemplate.cc && emailTemplate.cc.length == 0">
            <div class="input-group mb-2" >
              <input type="text" id="email-cc-0" class="form-control" v-model="emailTemplate.cc[0]"/>
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" @click="addCC()">
                  <i class="fa fa-plus"></i>
                </button>
                <!-- <button class="btn btn-sm btn-primary" @click="deleteOverview(indexOverview)" v-else> <i class="fa fa-minus"></i></button> -->
              </div>
            </div>
            </template>
            <template v-else-if="emailTemplate.cc && emailTemplate.cc.length > 0">
            <div class="input-group mb-2" v-for="item,index in emailTemplate.cc" :key="index">
              <input type="text" :id="`email-cc-${index}`" class="form-control" v-model="emailTemplate.cc[index]"/>
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" @click="addCC()" v-if="index == emailTemplate.cc.length - 1">
                  <i class="fa fa-plus"></i>
                </button>
                <button class="btn btn-sm btn-primary" @click="deleteCC(index)" v-else>
                  <i class="fa fa-minus"></i>
                </button>
              </div>
            </div>
            </template>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-2 text-right pt-2">BCC</div>
          <div class="col-md-8">
            <template v-if="emailTemplate.bcc && emailTemplate.bcc.length == 0">
            <div class="input-group mb-2" >
              <input type="text" id="email-bcc-0" class="form-control" v-model="emailTemplate.bcc[0]"/>
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" @click="addBCC()">
                  <i class="fa fa-plus"></i>
                </button>
                <!-- <button class="btn btn-sm btn-primary" @click="deleteOverview(indexOverview)" v-else> <i class="fa fa-minus"></i></button> -->
              </div>
            </div>
            </template>
            <template v-else-if="emailTemplate.bcc && emailTemplate.bcc.length > 0">
            <div class="input-group mb-2" v-for="item,index in emailTemplate.bcc" :key="index">
              <input type="text" :id="`email-bcc-${index}`" class="form-control" v-model="emailTemplate.bcc[index]"/>
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" @click="addBCC()" v-if="index == emailTemplate.bcc.length - 1">
                  <i class="fa fa-plus"></i>
                </button>
                <button class="btn btn-sm btn-primary" @click="deleteBCC(index)" v-else>
                  <i class="fa fa-minus"></i>
                </button>
              </div>
            </div>
            </template>
          </div>
        </div>
        <div class="row mb-4" v-if="!emailTemplate.linkFile || !isDownloadLink">
          <div class="col-md-2 text-right pt-2">Link File</div>
          <div class="col-md-8">
            <input type="file" id="email-file" class="form-control" accept=".html" @change="loadTextFromFile"/>
            <a href="#" v-if="emailTemplate.linkFile" @click.prevent="isDownloadLink = !isDownloadLink">Back</a>
          </div>
        </div>
        <div class="row mb-4" v-else>
          <div class="col-md-2 text-right pt-2">Template</div>
          <div class="col-md-8">
            <button class="btn btn-outline-success" @click="downloadTemplate($route.params.id)">Download</button>
            <a href="#" @click.prevent="isDownloadLink = !isDownloadLink">Upload Template</a>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="dataProps.templateName == 'fcmTemplate'">
      <div>
        <div class="row mb-4">
          <div class="col-md-3 text-right pt-2">Action</div>
          <div class="col-md-8">
            <input type="text" id="fcm-action" class="form-control" v-model="fcmTemplate.action"/>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3 text-right pt-2">Content</div>
          <div class="col-md-8">
            <textarea rows="5" type="text" id="fcm-content" class="form-control"  v-model="fcmTemplate.content"/>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3 text-right pt-2">Title</div>
          <div class="col-md-8">
            <input type="text" id="fcm-title" class="form-control" v-model="fcmTemplate.title"/>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="dataProps.templateName == 'whatsappTemplate'">
      <div>
        <div class="row mb-4">
          <div class="col-md-3 text-right pt-2">ID Template</div>
          <div class="col-md-8">
            <input type="text" id="wa-template" class="form-control" v-model="whatsappTemplate.whatsappTemplateId"/>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3 text-right pt-2">Params Mapping</div>
          <div class="col-md-8">
            <div class="row" v-for="item, index in whatsappTemplate.paramMappings" :key="index">
              <div class="col-md-5">
                  <div class="form-group">
                      <label class="m-0" for="">whatsappParam</label>

                <input type="text" :id="`whatsappParam-${index}`" class="form-control" v-model="whatsappTemplate.paramMappings[index].whatsappParam"/>
                  </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                      <label for="" class="m-0">templateParam</label>

                <input type="text" :id="`templateParam-${index}`" class="form-control" v-model="whatsappTemplate.paramMappings[index].templateParam"/>
                  </div>
              </div>
              <div class="col-md-2">
                <button class="btn btn-sm btn-primary mt-3 " @click="addWhatsappTemplate()" v-if="index == whatsappTemplate.paramMappings.length - 1">
                  <i class="fa fa-plus"></i>
                </button>
                 <button class="btn btn-sm btn-primary mt-3 " @click="deleteWhatsappTemplate(index)" v-else>
                  <i class="fa fa-minus"></i>
                </button>
              </div>
            </div>
             
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  data() {
      return {
          isDownloadLink: true
      }
  },
  props: {
    dataProps: {
      type: Object,
      default: () => {},
    },
  },
  computed:{
    ...mapState('NotifikasiStore', {
      smsTemplate: (state) => state.smsTemplate,
      fcmTemplate: state => state.fcmTemplate,
      whatsappTemplate: state => state.whatsappTemplate,
      emailTemplate: state => state.emailTemplate
    })
  },
  methods: {
    addCC() {
      this.emailTemplate.cc.push('')
    },
    deleteCC(index) {
      this.emailTemplate.cc.splice(index, 1)
    },
    addBCC() {
      this.emailTemplate.bcc.push('')
    },
    deleteBCC(index) {
      this.emailTemplate.bcc.splice(index, 1)
    },
    addWhatsappTemplate() {
      const paramMapping = {
          whatsappParam: '',
          templateParam: ''
      }
      this.whatsappTemplate.paramMappings.push(paramMapping)
    },
    deleteWhatsappTemplate(index) {
      this.whatsappTemplate.paramMappings.splice(index, 1)
    },
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const that = this
      reader.onload = function () {
        that.emailTemplate.file = reader.result.substring(reader.result.indexOf(',') + 1)
      };
    },
    ...mapActions({
        downloadTemplate: 'NotifikasiStore/DOWNLOAD_TEMPLATE_EMAIL'
    })
  }
};
</script>

<style>
</style>